import React from 'react';
import { useTranslation } from "react-i18next";
import { getLocale } from "../services/lang";
const ListTools: React.FC = () => {
  const { t } = useTranslation();
  const curLang = getLocale();

  return (
    <div>
      List Tools
    </div>
  );
};

export default ListTools;
