import { CSSProperties, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import useAppStore from "./store/appStore";

// Font imports
import '@fontsource/montserrat';
import '@fontsource/montserrat/300.css'; // Light
import '@fontsource/montserrat/400.css'; // Regular
import '@fontsource/montserrat/500.css'; // Medium
import '@fontsource/montserrat/700.css'; // Bold

// Style imports
import "bootstrap/dist/css/bootstrap.min.css";

// Page imports
import Home             from "./pages/Home";
import AboutUs          from "./pages/AboutUs";
import BusinessFields   from "./pages/BusinessFields";
import CompanyMembers   from "./pages/CompanyMembers";
import InternalNews     from "./pages/InternalNews";
import ContactUs        from "./pages/ContactUs";
import Product          from "./pages/Product";
import Project          from "./pages/Project";
import News             from "./pages/News";
import ImageUpload      from "./api/imgbb";
import CKEditorPage     from "./pages/ckeditor";
import TourismBusiness  from "./features/product/components/TourismBusiness";
import CkeditorViewer   from "./pages/CkeditorViewer";
import ListTool         from "./pages/ListTools";
import Tools            from "./pages/Tools";
// Types
export type Tag = {
  id: string;
  label: string;
};

// Styles
const appStyle: CSSProperties = {
  minWidth: "376px",
  fontFamily: "'Montserrat', sans-serif",
  fontWeight: 400, // Regular weight
  
};

function App() {
  const location = useLocation();
  const setCurrentPath = useAppStore((state) => state.setCurrentPath);
  // Use useEffect to set the current path
  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname, setCurrentPath]);
  
  return (
    <div style={appStyle}>
      <Routes>
        <Route path="/"                   element={<Home />} />
        <Route path="/aboutUs"            element={<AboutUs />} />
        <Route path="/businessFields"     element={<BusinessFields />} />
        <Route path="/companyMembers"     element={<CompanyMembers />} />
        <Route path="/contactUs"          element={<ContactUs />} />
        <Route path="/tourismBusiness"    element={<TourismBusiness />} />
        <Route path="/upload"             element={<ImageUpload />} />
        <Route path="/ckeditor"           element={<CKEditorPage />} />
        <Route path="/ckeditorViewer"     element={<CkeditorViewer clobData={""} />} />
        <Route path="/product/:id"        element={<Product />} />
        <Route path="/project/:id"        element={<Project />} />
        <Route path="/tools"              element={<ListTool />} />
        <Route path="/tool/:id"           element={<Tools />} />
        {/* <Route path="/news"               element={<InternalNews />} /> */}
        <Route path="/news/:id"           element={<News />} />
        <Route path="*"                   element={<Navigate to="/" replace />} />
      </Routes>
    </div>
  );
}

export default App;
