import { ButtonGroup, DropdownButton } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useTranslation } from "react-i18next";
import "../assets/style/LbNavbar.scss";

export default function LBNavbar() {
  const { t } = useTranslation();

  return (
    <div className="lb-navbar">
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/aboutUs" className="px-2">{t("content.common.aboutUs")}           </Nav.Link>
              <Nav.Link href="/businessFields" className="px-2">{t("content.common.fields")}     </Nav.Link>
              <NavDropdown title={t("content.common.products")} id="products-dropdown" className="px-2">
                <NavDropdown title={t("content.common.investment")} id="investment-dropdown">
                  <NavDropdown.Item href="/product/complexSocTrang">      {t("content.navbar.complexSocTrang")}   </NavDropdown.Item>
                  {/* glamping chain */}
                  <NavDropdown title={t("content.navbar.glampingChain")} id="glamping-chain-dropdown">
                    {/* tuyenQuangGlamping */}
                    <NavDropdown.Item href="/product/tuyenQuangGlamping"> {t("content.common.tuyenQuangGlamping")}</NavDropdown.Item>
                    {/* dienBienGlamping */}
                    <NavDropdown.Item href="/product/dienBienGlamping">   {t("content.common.dienBienGlamping")}  </NavDropdown.Item>
                    {/* caoBangGlamping */}
                    <NavDropdown.Item href="/product/caoBangGlamping">    {t("content.common.caoBangGlamping")}   </NavDropdown.Item>
                  </NavDropdown>
                  {/* tourism */}
                  <NavDropdown.Item href="/tourismBusiness">      {t("content.navbar.tourism")}           </NavDropdown.Item>
                </NavDropdown>
                {/* trading */}
                <NavDropdown title={t("content.common.trading")} id="trading-dropdown">
                  <NavDropdown title={t("content.navbar.exportImport")} id="exportImport-dropdown">
                    {/* woodPelet */}
                    <NavDropdown.Item href="/product/woodPelet">          {t("content.common.woodPelet")}         </NavDropdown.Item>
                    {/* spacon */}
                    <NavDropdown.Item href="/product/spacon">             {t("content.common.spacon")}            </NavDropdown.Item>
                    {/* steelSpan */}
                    <NavDropdown.Item href="/product/steelSpan">          {t("content.common.steelSpan")}         </NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown title={t("content.navbar.newTechMaterial")} id="newTechMaterial-dropdown">
                    {/* donghwa */} 
                    <NavDropdown.Item href="/product/donghwa">            {t("content.common.donghwa")}        </NavDropdown.Item>
                    {/* inExteriorPU */}
                    <NavDropdown.Item href="/product/inExteriorPU">       {t("content.common.inExteriorPU")}    </NavDropdown.Item>
                  </NavDropdown>
                </NavDropdown>
                {/* construction */}
                <NavDropdown  title={t("content.common.construction")} id="construction-dropdown">
                  <NavDropdown.Item href="/product/complexBuilding">      {t("content.navbar.complextHouseBuilding")}</NavDropdown.Item>
                  <NavDropdown.Item href="/product/resortNSpa">           {t("content.navbar.resortNSpa")}           </NavDropdown.Item>
                  <NavDropdown.Item href="/product/hightechContruct">     {t("content.navbar.houseTechnology")}   </NavDropdown.Item>
                  <NavDropdown.Item href="/product/inExteriorCompletion"> {t("content.navbar.inExteriorCompletion")} </NavDropdown.Item>
                </NavDropdown>
              </NavDropdown>
              <Nav.Link href="/companyMembers" className="px-2">{t('content.common.members')}   </Nav.Link>
              <Nav.Link href="/news" className="px-2">{t('content.common.news')}                </Nav.Link>
              <Nav.Link href="/contactUs" className="px-2">{t('content.common.contactUs')}      </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}
