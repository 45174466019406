import { useEffect, useState } from "react";
import styled from "styled-components";

interface ImageWithCaptionProps {
    imgUrl : string;
    text: string;
}

// Create a styled component for the layout
const CaptionImage = styled.div`
  div {
    text-align: center;
    font-style: italic;
    border: 1px solid #ccc;
    border-radius: 10px;
    img {
        width: 100%;
    }
    p {
        margin-top: 12px;
    }
  }
`;

const ImageWithCaption: React.FC<ImageWithCaptionProps> = ({ imgUrl, text }) => {
    const [url, setUrl]         = useState<string>('');
    const [caption, setCaption] = useState<string>('');

    useEffect(() => {
        setUrl(imgUrl);
        setCaption(text);
    }, [imgUrl, text]);

    return (
        <CaptionImage className="CaptionImage">
            <div>
                <img src={url} alt={caption} />
                <p>{caption}</p>
            </div>
        </CaptionImage>
    );
};

export default ImageWithCaption;