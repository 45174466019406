import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import React from "react";
import i18n from "./services/i18n";
import { I18nextProvider } from "react-i18next";
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";
import { BrowserRouter } from "react-router-dom";
import "./Index.scss";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const AppContext = React.createContext({});

root.render(
  <AppContext.Provider value={{ lang: "en" }}>
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
          <Header />
          <main className="main-content">
            <App />
          </main>
          <Footer />
      </BrowserRouter>
    </I18nextProvider>
  </AppContext.Provider>
);

reportWebVitals();
