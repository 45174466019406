import ImageWithCaption from "../../../../components/ImageWithCaption";

const SpaconProduct = () => {
  return (
    <div className="post post_single">
      <div className="post-attachement">
        <ImageWithCaption imgUrl="https://i.ibb.co/c39XSX9/spacon1.jpg" text="" />
      </div>
      
      <div className="post_ctn clearfix">
        <div className="post-title">
          <h1>Thiết bị tiết kiệm điện cho điều hòa – Spacon</h1>
        </div>
        <div className="entry">
          <h5>
            Với mong muốn tối ưu hóa chi phí sử dụng năng lượng điện, góp phần bảo vệ môi trường, 
            Le Bruder đã hợp tác với Green Earth Viet Nam, thành viên của Green Earth JSC có trụ sở 
            tại Nhật Bản – công ty chuyên về sản phẩm tiệm năng lượng, để cung cấp và lắp đặt thiết bị 
            tiết kiệm điện cho hệ thống điều hòa không khí có tên gọi là Spacon.
          </h5>
          <ImageWithCaption imgUrl="https://i.ibb.co/c39XSX9/spacon1.jpg" text="Tấm Spacon được lắp trên điều hòa" />
          <h3>Spacon là gì?</h3>
          <p>
            Spacon viết tắt của "SUPER POWERED AIR CONDITIONER" thiết bị có thiết kế đặc biệt 
            kết hợp dung môi làm lạnh thế hệ mới giúp quá trình hóa lỏng môi chất lạnh nhanh và 
            triệt để hơn. Từ đó cải thiện hiệu suất làm việc và nâng cao tuổi thọ của thiết bị.
          </p>
          <ImageWithCaption imgUrl="https://i.ibb.co/YXPdnrb/spacon2.jpg" text="Tấm Spacon lắp đặt trên điều hòa tại khu công nghiệp" />
          <h3>Cấu tạo của Spacon</h3>
          <p>
            Cấu tạo bởi vật liệu chính là nhôm phủ sứ tản nhiệt, có tác dụng tăng cường trao đổi nhiệt, 
            làm môi chất lạnh hóa lỏng nhanh và triệt để hơn. Giúp tăng hiệu suất làm việc cũng như 
            tuổi thọ của thiết bị.
          </p>
          <ImageWithCaption imgUrl="https://i.ibb.co/12rXM2h/spacon3.jpg" text="Tấm Spacon áp dụng cho máy nén khi công suất lớn" />
          <h3>Những lợi ích mà Spacon mang lại</h3>
          <p>
            <b>Spacon</b> là một trong những thiết bị <b>tiết kiệm năng lượng điện</b>,giảm từ 10 đến 20% điện năng tiêu thụ. Đây là tiêu chí rất quan trọng để những khu công nghiệp, nhà máy chế biến, trung tâm thương mại…giảm thiểu lượng điện năng tiêu thụ hàng tháng.
            Spacon giúp <b>giảm lượng khí thải CO2</b> thải ra môi trường. Trên mỗi thiết bị có gắn hệ thống đo được lượng khí CO2 thải ra môi trường nhờ vậy khách hàng có thể biết được lượng khí thải phát ra hàng ngày, hàng tháng, hàng năm.
            Spacon cũng giúp kéo <b>kéo dài tuổi thọ</b>  của các thiết bị điều hòa không khí, giảm chi phí bảo trì và sửa chữa. Do cơ chế giúp thiết bị hóa lỏng khí gas nhanh hơn, giảm áp suất trong đường ống mà không giảm khả năng làm lạnh từ đó dẫn đến tăng tuổi thọ cho thiết bị.
          </p>
          <h3>Những lợi ích mà Spacon mang lại</h3>
          <p>
            Thiết bị Spacon được ứng dụng rộng rãi tại các <b>khu công nghiệp, nhà máy chế biến, trung tâm thương mại,...</b> những cơ sở sử dụng thiết bị điều hòa cỡ lớn.
          </p>
          <ImageWithCaption imgUrl="https://i.ibb.co/PjJ91Tr/spacon4.jpg" text="Thiết bị Spacon được ứng dụng rộng rãi" />
          <p>
            Tổng quan, <b>Spacon</b> mang lại nhiều lợi ích quan trọng. Nó không chỉ giúp tiết kiệm chi phí năng lượng và bảo vệ môi trường, mà còn cải thiện hiệu suất và tiện ích cho người sử dụng. Với những ưu điểm này, việc sử dụng Spacon là một sự lựa chọn thông minh và bền vững trong việc tiết kiệm điện và bảo vệ môi trường. Với mong muốn góp phần vào việc xây dựng một xã hội bền vững, <b>Le Bruder</b> sẽ giới thiệu rộng rãi sản phẩm tại nhiều khu công nghiệp, nhà máy, trên khắp các tỉnh tại Việt Nam.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SpaconProduct;
