import { useState, useEffect } from "react";
import { ImageObject } from "../data/type";
import "./ImageSlideShow.scss";

const slideEffects = [
  "fade",
  "slide-left",
  "slide-right",
  "slide-up",
  "slide-down",
  "fractal"
];

function ImageSlideShow({ imageList }: { imageList: ImageObject[] }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentEffect, setCurrentEffect] = useState(slideEffects[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % imageList.length);
      setCurrentEffect(slideEffects[Math.floor(Math.random() * slideEffects.length)]);
    }, 10000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, [imageList.length]);

  return (
    <div className="image-slideshow">
      <img
        src={imageList[currentIndex].url}
        alt={imageList[currentIndex].alt || "Slideshow image"}
        className={`slide-effect ${currentEffect}`}
      />
    </div>
  );
}

export default ImageSlideShow;
