import React, { useState, useEffect } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

interface CkeditorViewerProps {
  clobData: string;
}

const CkeditorViewer: React.FC<CkeditorViewerProps> = ({ clobData }) => {
  const [editorData, setEditorData] = useState<string>('');

  useEffect(() => {
    // Set the editor data when the component mounts or when clobData changes
    setEditorData(clobData);
  }, [clobData]);

  return (
    <div className="ckeditor-viewer">
      <h1>CLOB Data Viewer</h1>
      <CKEditor
        editor={ClassicEditor}
        data={editorData}
        disabled={true}
        config={{
          toolbar: [],
        }}
      />
    </div>
  );
};

export default CkeditorViewer;
