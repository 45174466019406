import React, { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const CKEditorPage: React.FC = () => {
  const [editorData, setEditorData] = useState<string>('');

  const handleExport = () => {
    // Convert editor data to CLOB (in this case, it's just a string)
    const clob = editorData;
    console.log('Exported CLOB:', clob);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">CKEditor Page</h1>
      <CKEditor
        editor={ClassicEditor}
        data={editorData}
        onChange={(event, editor) => {
          const data = editor.getData();
          setEditorData(data);
        }}
      />
      <button
        className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        onClick={handleExport}
      >
        Export to CLOB
      </button>
      <div className="mt-4">
        <h2 className="text-xl font-semibold mb-2">Editor Content:</h2>
        <div dangerouslySetInnerHTML={{ __html: editorData }} />
      </div>
    </div>
  );
};

export default CKEditorPage;
