import React, { Suspense } from "react";
import { useParams } from "react-router";
import PageNotFound from "./PageNotFound";
import ComplexBuilding from "../features/product/components/civilConstruct/ComplexBuilding";
import ConstructCompleting from "../features/product/components/civilConstruct/ConstructCompleting";
import ResortNSpa from "../features/product/components/civilConstruct/ResortNSpa";
import SpaconProduct from "../features/product/components/importExport/SpaconProduct";
import styles from "../assets/style/Home.module.scss";

const ComplexSocTrang = React.lazy(
  () => import("../features/product/components/ComplexSocTrang")
);
const TuyenQuangGlamping = React.lazy(
  () => import("../features/product/components/TuyenQuangGlamping")
);
const DienBienGlamping = React.lazy(
  () => import("../features/product/components/DienBienGlamping")
);
const CaoBangGlamping = React.lazy(
  () => import("../features/product/components/CaoBangGlamping")
);
const TourismBusiness = React.lazy(
  () => import("../features/product/components/TourismBusiness")
);
const SteelProduct = React.lazy(
  () => import("../features/product/components/importExport/SteelProduct")
);
const WoodPellet = React.lazy(
  () => import("../features/product/components/importExport/WoodPellet")
);
const DongwhaProduct = React.lazy(
  () => import("../features/product/components/inExterior/Dongwha")
);
const LightFanDecorProduct = React.lazy(
  () => import("../features/product/components/inExterior/LightFanDecorProduct")
);
const HouseHoldProduct = React.lazy(
  () => import("../features/product/components/inExterior/HouseHoldProduct")
);
const ConstructionMaterial = React.lazy(
  () => import("../features/product/components/inExterior/ConstructionMaterial")
);
const PUProduct = React.lazy(
  () => import("../features/product/components/inExterior/PUProduct")
);
const ConstructCompletion = React.lazy(
  () =>
    import("../features/product/components/civilConstruct/ConstructCompleting")
);
const HighTechConstruct = React.lazy(
  () =>
    import("../features/product/components/civilConstruct/HighTechConstruct")
);

const Product = () => {
  const params = useParams();
  let content;
  switch (params.id) {
    case "inExteriorCompletion":
      content = <ConstructCompletion />;
      break;
    case "complexSocTrang":
      content = <ComplexSocTrang />;
      break;
    // case "tuyenQuangGlamping":
    //   content = <TuyenQuangGlamping />;
    //   break;
    // case "dienBienGlamping":
    //   content = <DienBienGlamping />;
    //   break;
    // case "caoBangGlamping":
    //   content = <CaoBangGlamping />;
    //   break;
    // case "tourismBusiness":
    //   content = <TourismBusiness />;
    //   break;
    case "spacon":
      content = <SpaconProduct />;
      break;
    case "steelSpan":
      content = <SteelProduct />;
      break;
    case "woodPellet":
      content = <WoodPellet />;
      break;
    case "donghwa":
      content = <DongwhaProduct />;
      break;
    case "inExteriorPU":
      content = <PUProduct />;
      break;
    case "interiorDecor":
      content = <LightFanDecorProduct />;
      break;
    case "houseHoldEquipments":
      content = <HouseHoldProduct />;
      break;
    case "constructionMaterials":
      content = <ConstructionMaterial />;
      break;
    case "woodPelet":
      content = <WoodPellet />;
      break;
    case "complexBuilding":
      content = <ComplexBuilding />;
      break;
    case "hightechContruct":
      content = <HighTechConstruct />;
      break;
    case "constructCompleting":
      content = <ConstructCompleting />;
      break;
    case "resortNSpa":
      content = <ResortNSpa />;
      break;
    default:
      content = <PageNotFound />;
  }
  return (
    <>
      <div className={styles.bodyContent}>
        <div className={styles.inner}>
          <Suspense fallback={<div>Loading...</div>}>{content}</Suspense>
        </div>
      </div>

    </>
  );
};
export default Product;
