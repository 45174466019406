import React, { Suspense, lazy } from "react";
import { useParams } from "react-router";
import styles from "../assets/style/Home.module.scss";
import PageNotFound from "./PageNotFound";

const HopTacNectaris = React.lazy(
  () => import("../features/news/components/HopTacNectaris")
);
const BietThuVinhLong = React.lazy(
  () => import("../features/news/components/BietThuVinhLong")
);
const VisitDongwhaThaiNguyen1 = React.lazy(
  () => import("../features/news/components/VisitDongwhaThaiNguyen1")
);
const VisitDongwhaThaiNguyen2 = React.lazy(
  () => import("../features/news/components/VisitDongwhaThaiNguyen2")
);
const TayRuaKhuTrung = React.lazy(
  () => import("../features/news/components/TayRuaKhuTrung")
);
const LeBruderSocTrang = React.lazy(
  () => import("../features/news/components/LeBruderSocTrang")
);
const BreakChallenge = React.lazy(
  () => import("../features/news/components/BreakChallenge")
);
const News = () => {
  const params = useParams();
  let content;
  switch (params.id) {
    case "hop-tac-nectatis":
      content = <HopTacNectaris />;
      break;
    case "biet-thu-vinh-long":
      content = <BietThuVinhLong />;
      break;
    case "dongwha-thai-nguyen-1":
      content = <VisitDongwhaThaiNguyen1 />;
      break;
    case "dongwha-thai-nguyen-2":
      content = <VisitDongwhaThaiNguyen2 />;
      break;
    case "khu-trung-tay-rua":
      content = <TayRuaKhuTrung />;
      break;
    case "lebruder-soc-trang":
      content = <LeBruderSocTrang />;
      break;
    case "pha-thu-thach":
      content = <BreakChallenge />;
      break;
    default:
      content = <PageNotFound />;
  }
  return (
    <div className={styles.bodyContent}>
      <div className={styles.inner}>
        <Suspense fallback={<div>Loading...</div>}>{content}</Suspense>
      </div>
    </div>
  );
};
export default News;
