import { useState } from "react";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownItem from "react-bootstrap/DropdownItem";
import PropTypes from "prop-types";
import i18n from "../../services/i18n";
import "../../assets/style/LanguagePicker.scss";
import { AppLanguages } from "../../data/constants";

const LanguagePicker = () => {
  const [langId, setLang] = useState("vi");
  const [isVisible, setIsVisible] = useState(false);
  const [iconHidden, setIconHidden] = useState(false);

  const currentLanguage = localStorage.i18nextLng;
  if (currentLanguage !== langId) {
    setLang(currentLanguage)
  }

  const getLabel = (id: string) => {
    return AppLanguages.find((item) => item.id === id)?.label;
  };

  const selectLanguage = (e: any): any => {
    const id = e.target.id;
    i18n.changeLanguage(id);
    setLang(id);
  };

  const handleIconClick = () => {
    setIconHidden(true);
    setIsVisible(true);
  };

  return (
    <div 
      className="languagePicker"
    >
      <LanguageOutlinedIcon 
        className={`languageIcon ${iconHidden ? 'hidden' : ''}`}
        onClick={handleIconClick}
      />
      <div className={`dropdownWrapper ${iconHidden ? 'visible' : ''}`}>
        <Dropdown
          show={isVisible}
          onToggle={(isOpen) => {
            setIsVisible(isOpen);
            if (!isOpen) setIconHidden(false);
          }}
          align="end"
        >
          <Dropdown.Toggle variant="light" id="language-dropdown">
            {/* {getLabel(langId)} */}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              onClick={selectLanguage}
              id="en"
              active={langId === "en"}
              className="custom-dropdown-item"
            >
              English
            </Dropdown.Item>
            <Dropdown.Item
              onClick={selectLanguage}
              id="vi"
              active={langId === "vi"}
              className="custom-dropdown-item"
            >
              Tiếng Việt
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default LanguagePicker;

LanguagePicker.propTypes = {
  name: PropTypes.string,
};
