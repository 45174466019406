import React, { useRef } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/News.scss";
import LBCard from "../../../components/ui/LBCard";
import { projectPost } from "../../../data/productPost";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

interface TypicalProductsProps {
  deviceType?: string;
  lang?: string;
}

const TypicalProducts: React.FC<TypicalProductsProps> = ({ deviceType, lang }) => {
  const PrevArrow = (props: any) => {
    return (
      <button
        className="slick-arrow slick-prev"
        onClick={props.onClick}
      >
        <FaChevronLeft />
      </button>
    );
  };

  const NextArrow = (props: any) => {
    return (
      <button
        className="slick-arrow slick-next"
        onClick={props.onClick}
      >
        <FaChevronRight />
      </button>
    );
  };

  const settings = {
    dots          : false,
    infinite      : true,
    speed         : 500,
    slidesToShow  : 3,
    slidesToScroll: 1,
    autoplay      : true,
    autoplaySpeed : 5000,
    responsive    : [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },{
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ],
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  const sliderRef = useRef<Slider | null>(null);
  const next = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };
  const previous = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };
  return (
    <div className="news">
      <Slider 
        ref={sliderRef}
        {...settings}>
        {projectPost.map((project) => {
          const title     = `title${lang}` as keyof typeof project;
          const shortDesc = `shortDesc${lang}` as keyof typeof project;
          return (
            <LBCard
              target    ="product"
              id        ={project.id}
              key       ={project.id}
              title     ={project[title] as string}
              imageUrl  ={project.image}
              shortDesc ={project[shortDesc] as string}
            />
          );
        })}
      </Slider>
      <div className='button-group'>
        <button className="button" onClick={previous}>
          &lt;
        </button>
        <button className="button" onClick={next}>
          &gt;
        </button>
      </div>
    </div>
  );
};

export default TypicalProducts;
