import { Col, Row } from "react-bootstrap";
import ImageSlideShow from "../components/ImageSlideShow";
import { imageList } from "../data/constants";
import "../assets/style/CompanyMember.scss"
import styles from "../assets/style/Home.module.scss";

const CompanyMembers = () => {
  const bm = require("../assets/images/icons/baumarkt-1-200x200.png");
  const gl = require("../assets/images/icons/glamping-200x200.jpg");
  const ec = require("../assets/images/icons/lebruder-ec-200x200.png");
  return (
    <div className={styles.bodyContent}>
      <div className={styles.inner}>
        <div className="company-member">
          <div>
            <ImageSlideShow imageList={imageList} />
          </div>
          <Row className="member">
            <Col md={3}>
              <img src={ec} alt="enc" />
            </Col>
            <Col md={9} className="member-info">
              <h3>CÔNG TY CỔ PHẦN XÂY DỰNG VÀ KỸ THUẬT LE BRUDER</h3>
              <hr />
              <div>
                <b>
                  Ngành nghề kinh doanh: Xây dựng – Cung cấp vật liệu chất lượng cao
                </b>
                <br />
                <b> Thông tin mô tả:</b> CÔNG TY CỔ PHẦN XÂY DỰNG VÀ KỸ THUẬT LE
                BRUDER viết tắt là LE BRUDER E&C, được thành lập từ 09/09/2022 từ
                một nhóm đội ngũ kĩ sư trẻ đoàn kết; chính trực. Với lòng yêu nghề
                và sự quyết tâm vươn tới những tri thức mới, chúng tôi sẽ mang tới
                những “chân giá trị” thật nhất tới Quý khách hàng.
              </div>
            </Col>
          </Row>
          <Row className="member">
            <Col md={3}>
              <img src={bm} alt="baumarkt" />
            </Col>
            <Col md={9} className="member-info">
              <h3>CÔNG TY TNHH BAUMARKT</h3>
              <hr />
              <div>
                <b>
                  Ngành nghề kinh doanh: Sàn thương mại điện tử
                </b>
                <br />
                <b>Thông tin mô tả:</b> Phân phối trực tuyến VLXD chất lượng cao trên thị trường trong nước và quốc tế.
                <br />
                <b>Website</b>: <a href="http://www.baumarkt.vn">www.baumarkt.vn</a>
              </div>
            </Col>
          </Row>
          <Row className="member">
            <Col md={3}>
              <img src={gl} alt="hongthai" />
            </Col>
            <Col md={9} className="member-info">
              <h3>CÔNG TY CỔ PHẦN LE BRUDER HỒNG THÁI</h3>
              <hr />
              <div>
                <b>
                  Ngành nghề kinh doanh: Sàn thương mại điện tử
                </b>
                <br />
                <b> Thông tin mô tả:</b> Mang tới cho Du khách những trải nghiệm thiên nhiên sang trọng với sự kết hợp hài hòa văn hóa địa phương thông qua các hoạt động nghỉ dưỡng, vui chơi và ẩm thực.
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};
export default CompanyMembers;
