import React from 'react';
import { Col, Row } from 'react-bootstrap';
import styles from "../assets/style/Home.module.scss";
import ImageSlideShow from "../components/ImageSlideShow";
import { imageList } from "../data/constants";
import LBNews from "../features/home/components/LBNews";
import LBPartnerIntroduce from "../features/home/components/LBPartnerIntroduce";
import { useTranslation } from "react-i18next";
import TypicalProducts from "../features/home/components/TypicalProducts";
import BusinessFieldInfo from "../components/ui/BusinessFieldInfo";
import { getLocale } from "../services/lang";
import SEO from '../layouts/SEO';
const Home: React.FC = () => {
  const { t } = useTranslation();
  const curLang = getLocale();

  // Move renderSection outside of the component
  const renderSection = (title: string, content: React.ReactNode) => (
    <section className={styles.section}>
      <h3 className={styles.sectionTitle}>{t(title)}</h3>
      <hr />
      {content}
    </section>
  );

  return (
    <div>
      <SEO 
        title="Le Bruder., JSC"
        description="Welcome to my JSC Lebruder"
        keywords={["ha tang", "hatang", "ha tang ngam", "lebruder hatang", "Le Bruder Nen Mong", "Xu ly nen mong", "LEBRUDER", "LE BRUDER"]}
      />
      <div className={styles.mainBanner}>
        <ImageSlideShow imageList={imageList} />
      </div>
      <div className={styles.bodyContent}>
        <div className={styles.inner}>
          {renderSection('content.common.businessFields', <BusinessFieldInfo />)}

          <section className={styles.product}>
            <Row>
              <Col md={2}>
                <h3>{t("content.home.typicalProjects")}</h3>
              </Col>
              <Col md={10}>
                <TypicalProducts lang={curLang} />
              </Col>
            </Row>
          </section>

          <section className={styles.product}>
            <Row>
              <Col md={2} className={styles.productHeader1}>
                <h3>{t("content.home.typicalProduct")}</h3>
              </Col>
              <Col md={10}>
                <TypicalProducts lang={curLang} />
              </Col>
            </Row>
          </section>

          {renderSection('content.home.featureNews', <LBNews lang={curLang} />)}
          {renderSection('content.home.partner', <LBPartnerIntroduce />)}
        </div>
      </div>
    </div>
  );
};

export default Home;
