import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styles from '../assets/style/Header.module.scss';
import LBNavbar from './LBNavbar';
import logo from '../assets/images/logo-LeBruder-01.png';
import LanguagePicker from "../components/ui/LanguagePicker";

const Header: React.FC = () => {
  return (
    <header className={styles.header}>
      <div className={styles.headerLine} />
      <Container fluid className={styles.headerContainer}>
        <Row className={styles.headerContent}>
          <Col xs={12} sm={12} md={12} lg={3} className={styles.logoWrapper}>
            <div className={styles.logoBackground}>
              <a href="/">
              <img src={logo} alt="Le Bruder JSC logo" className={styles.logo} />
              </a>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={9} className={styles.navbarWrapper}>
            <LBNavbar />
          </Col>
        </Row>
        <div className={styles.languagePickerWrapper}>
          <LanguagePicker />
        </div>
      </Container>
    </header>
  );
};

export default Header;
