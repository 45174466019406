import ImageWithCaption from "../../../../components/ImageWithCaption";

const ConstructCompleting = () => {
  return <div className="post post_single">
  <div className="post-attachement">
    <ImageWithCaption imgUrl="https://i.ibb.co/gvJQ77n/Hoan-thien-noi-that-An-Binh-439x382.jpg" text="" />
  </div>
  
  <div className="post_ctn clearfix">
    <div className="post-title">
      <h1>Hoàn thiện nội, ngoại thất</h1>
    </div>
    <div className="entry">
      <h5>
        <b>Le Bruder</b> hiểu rằng không gian sống là nơi có thể thể hiện phong cách cá nhân của bạn. Với sự sáng tạo không ngừng, chúng tôi cam kết mang đến cho Quý khách hàng những giải pháp thiết kế và sản phẩm trang trí nội, ngoại thất tối ưu, tạo ra không gian sống, làm việc đẹp mắt, sáng tạo, tiện nghi và chất lượng cao.
      </h5>
        <ImageWithCaption imgUrl="https://i.ibb.co/gvJQ77n/Hoan-thien-noi-that-An-Binh-439x382.jpg" text="" />
      <br></br>
        <ImageWithCaption imgUrl="https://i.ibb.co/bWdFfqF/Hoan-thien-noi-that-Trinity-Tower.jpg"   text="" />
      <p>
        Quý khách hàng hoàn toàn an tâm đặt chọn niềm tin vào chất lượng thi công, sản phẩm mà Le Bruder cung cấp, phân phối, cùng đội ngũ nhân sự nhiều năm kinh nghiệm, năng động, luôn cải tiến trong lĩnh vực <b>Xây dựng dân dụng</b> chắc chắn sẽ mang tới cho bạn những sản phẩm vô cùng hoàn hảo, sự sáng tạo trong thiết kế, các giải pháp xây dựng thông minh và hiện đại nhất.
      </p>
    </div>
  </div>
</div>;
};
export default ConstructCompleting;
