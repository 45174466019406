import { ImageObject } from "./type";

export const EAppConstants = {
    companyName: "Le Bruder",
    imgbbApiKey: '3450d60e1e74495f9cf8b6f76d4b786f', // Replace with your actual API key
    googleMapKey: 'AIzaSyDQtDOEs6mo6hrf4u-HRvmsExNsXLYKqGo',
    googleMapUrl: 'https://www.google.com/maps/place/21%C2%B001\'45.1"N+105%C2%B046\'31.2"E/@21.029204,105.7746963,257m/data=!3m2!1e3!4b1!4m4!3m3!8m2!3d21.029204!4d105.77534?hl=vi-VN&entry=ttu&g_ep=EgoyMDI0MTAwMS4wIKXMDSoASAFQAw%3D%3D',
    lng: 105.7773269,
    lat: 21.0057787,
    width: 100,
    height: 35
}

export const enum CkeditorData {

}

export const enum ContactInfo {
    phone1 = '090 123 0522',
    phone2 = '090 440 3289',
    infoEmail = 'info@lebruder.com',
    director = 'Nguyễn Mạnh Chiến'
}

const AppLanguages = [
    { id: "en", label: "English" },
    { id: "vi", label: "Tiếng Việt" },
];

export const enum MessageType {
    ERROR,
    SUCCESS
}

const enum AboutUsImages {
    banner       = 'https://lebruder.com/wp-content/uploads/2023/01/111.jpg',
    greetingCard = 'https://lebruder.com/wp-content/uploads/2022/10/photo-08.png',
    orgChart     = 'https://lebruder.com/wp-content/uploads/2023/05/orgChart-1-scaled-1536x916.jpg',
    vision       = 'https://lebruder.com/wp-content/uploads/2022/12/vision-1-1536x1024.png',
    mission      = 'https://lebruder.com/wp-content/uploads/2022/12/mission-1536x1024.png',
    coreValue    = 'https://lebruder.com/wp-content/uploads/2022/12/Core-1536x1024.png'

}
export const imageList: ImageObject[] = [
    {
        url: "https://i.ibb.co/5ctxdsr/banner-Le-Bruder-001.jpg",
        title: "Home 1",
        alt: "Home 1",
        id: "1"
    },
    {
        url: "https://i.ibb.co/k1QJqmJ/banner-Le-Bruder-002.jpg",
        title: "Home 1",
        alt: "Home 1",
        id: "2"
    },
    {
        url: "https://i.ibb.co/w7fCQTF/banner-Le-Bruder-003.jpg",
        title: "Home 1",
        alt: "Home 1",
        id: "3"
    },
    {
        url: "https://i.ibb.co/YbPm737/banner-Le-Bruder-004.jpg",
        title: "Home 1",
        alt: "Home 1",
        id: "4"
    },
    {
        url: "https://i.ibb.co/gds9zFK/banner-Le-Bruder-005.jpg",
        title: "Home 1",
        alt: "Home 1",
        id: "5"
    },
];

const enum bussinessAreaImgs {
    tourRealEstate      = 'https://lebruder.com/wp-content/uploads/2023/05/glampin-3-scaled-2048x1239.jpg',
    trading             = 'https://lebruder.com/wp-content/uploads/2023/05/16273d771dfbc2a59bea6-1-scaled-2048x1239.jpg',
    civilConstruction   = 'https://lebruder.com/wp-content/uploads/2023/05/aea79af7ba7b65253c6a7-1-scaled-2048x1239.jpg'
};

const newsReponsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
}

const responsive = {
    midDesktop: {
        breakpoint: { max: 2047, min: 1280 },
        items: 3,
    },
    minDesktop: {
        breakpoint: { max: 1279, min: 1069 },
        items: 2,
    },
    tablet: {
        breakpoint: { max: 1068, min: 464 },
        items: 1,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};

const responsivePartnerLogo = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
    },
};

const partnerList = [
    "https://i.ibb.co/NSgZsx2/1-gpkg.png",
    "https://i.ibb.co/9NMpd5G/2-BMH.jpg",
    "https://i.ibb.co/HHmYdCs/3-USF.png",
    "https://i.ibb.co/4728Vwk/4-Green.jpg",
    "https://i.ibb.co/d6dz30d/5-Dongwha-1.png",
    "https://i.ibb.co/Jzqv6r3/6-NEC.jpg",
    "https://i.ibb.co/k428k7s/7-KQT.png",
    "https://i.ibb.co/nzVN1yB/8-Bellus.jpg",
    "https://i.ibb.co/tZpxwDz/9-photo.png",
    "https://i.ibb.co/PCsncyK/10-cienco4.png",
    "https://i.ibb.co/VNKqsVZ/11-HaDo.png",
]

export {
    responsive, AboutUsImages, bussinessAreaImgs, AppLanguages,
    partnerList, responsivePartnerLogo
};