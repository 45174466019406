import { Col, Row } from "react-bootstrap";
import { useState } from "react";
import "../assets/style/ContactUs.scss";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { EAppConstants } from "../data/constants";
import styles from "../assets/style/Home.module.scss";
import SEO from "../layouts/SEO";

const ContactUs = () => {
  const [guestName, setGuestName]       = useState("");
  const [guestPhone, setGuestPhone]     = useState("");
  const [guestEmail, setGuestEmail]     = useState("");
  const [guestMessage, setGuestMessage] = useState("");

  const changeGuestMessage = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setGuestMessage(e.target.value);
  };

  const changeGuestName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGuestName(e.target.value);
  };

  const changeGuestEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGuestEmail(e.target.value);
  };

  const changeGuestPhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGuestPhone(e.target.value);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log({ guestName, guestEmail, guestPhone, guestMessage });
  };

  return (
    <div className={styles.bodyContent}>
      <SEO 
        title="Le Bruder Holding Contact"
        description="Liên hệ chúng tôi Le Bruder"
        keywords={["ha tang", "hatang", "ha tang ngam", "lebruder hatang", "Le Bruder Nen Mong", "Xu ly nen mong", "LEBRUDER", "LE BRUDER Holding contact"]}
      />
      <div className={styles.inner}>
        <div>
          <div>
            <a href={EAppConstants.googleMapUrl} target="_blank">
              <img src={require("../assets/images/hardmap.png")} alt="Contact Map" width={"100%"} />
            </a>
          </div>
          <div className="contactUs">
            {/* <LBMap /> */}
            <Row>
              {/* <Col xs={12} sm={12} md={6}> */}
              {/* <form onSubmit={onSubmit}>
            <div>
              <label>Tên của bạn</label>
              <br />
              <input
                type="text"
                name="guestName"
                value={guestName}
                onChange={changeGuestName}
              />
              <p className="error">* Vui lòng điền tên</p>
              <label>Địa chỉ email</label>
              <br />
              <input
                type="text"
                name="guestEmail"
                value={guestEmail}
                onChange={changeGuestEmail}
              />
              <p className="error">* Vui lòng điền số email</p>
              <label>Số điện thoại</label>
              <br />
              <input
                type="text"
                name="guestPhone"
                value={guestPhone}
                onChange={changeGuestPhone}
              />
              <p className="error">* Vui lòng số điện thoại</p>
              <label>Nội dung</label>
              <br />
              <textarea
                name="guestMessage"
                rows={6}
                value={guestMessage}
                onChange={changeGuestMessage}
              />
            </div>
            <div className="btn-group">
              <input type="submit" value="Gửi yêu cầu" />
            </div>
          </form> */}
              {/* </Col> */}
              <Col xs={12} sm={12} md={6}>
                <h3>CÔNG TY CỔ PHẦN LE BRUDER</h3>
                <p>
                  <LocationOnOutlinedIcon /> <b> Địa chỉ:</b> ND4-A11 Ngõ 3 Nguyễn Hoàng, P. Mỹ Đình 2, Q. Nam Từ Liêm, Hà Nội, Việt Nam
                </p>
                <br />
                <p>
                  <LocationOnOutlinedIcon /> <b> Địa chỉ:</b> 90C Lê Văn Khương, Đông Thạnh, Hóc Môn, Hồ Chí Minh, Việt Nam
                </p>
                <br />

                <p>
                  <LocalPhoneOutlinedIcon /> <b>Hotline:</b> 0901 23 05 22
                </p>
                <br />
                <p>
                  <EmailOutlinedIcon /> <b>Email:</b> info@lebruder.com
                </p>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactUs;
